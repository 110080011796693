import { Link } from "gatsby";
import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

const Content = () => {
  const [isMonthly, setIsMonthly] = useState(false);

  return (
    <>
      {/* <!-- Content Area --> */}
      <div
        id="prices"
        className="content-section2 pt-12 pb-9 pt-lg-21 pb-lg-12 bg-default-2"
      >
        <Container>
          <Row className="justify-content-center">
            <Col xl="6" lg="8" sm="10">
              <div className="section-title text-center mb-12 mb-lg-17">
                <h2 className="title gr-text-4 mb-7">
                  <Trans>prices.title</Trans>
                </h2>
                <p className="gr-text-8 px-lg-7 px-xl-0">
                  <Trans>prices.subline</Trans>
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="pricing-section bg-default-2 pb-7 pb-lg-20">
          <Container className="ml-md-30 mr-md-30 w-auto mw-100">
            <div className="text-center pt-9">
              <div
                className="mb-13 d-inline-flex position-relative"
                id="pricing-dynamic-deck--head"
              >
                <span className="period month gr-text-8 gr-text-color ">
                  <Trans>prices.monthly</Trans>
                </span>
                <a
                  href="/#"
                  className={`btn-toggle mx-3 price-deck-trigger ${
                    isMonthly ? "active" : "inactive"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsMonthly(!isMonthly);
                  }}
                >
                  <span className="round"></span>
                </a>
                <span className="period year gr-text-8 gr-text-color">
                  <Trans>prices.yearly</Trans>
                </span>
                <span className="badge gr-badge text-primary gr-text-12 gr-bg-blue-opacity-1 rounded-pill ml-5 text-uppercase">
                  <Trans>prices.save</Trans>
                </span>
              </div>
              <Row className="justify-content-center">
                <Col lg="3" md="6" sm="10" className="mb-9">
                  <div className="pricing-card bg-white gr-hover-shadow-1 border text-left pt-9 pb-9 pr-9 pr-xl-13  pl-9 pl-xl-13 bg-white rounded-10">
                    <div className="price-content">
                      <span className="small-title gr-text-12 text-primary font-weight-bold text-uppercase">
                        Starter
                      </span>
                      <div className="d-flex align-items-end mt-11 ">
                        <span className="currency mr-2 gr-text-6 font-weight-bold line-spacing-none text-blackish-blue">
                          <Trans>global.currency-i</Trans>
                        </span>
                        <h2 className="price-value gr-text-2 font-weight-bold line-spacing-none mb-0 text-blackish-blue">
                          {isMonthly ? (
                            <Trans>prices.p1</Trans>
                          ) : (
                            <Trans>prices.p1</Trans>
                          )}
                          <span className="d-none">.</span>
                        </h2>
                        <span className="per gr-text-9 text-blackish-blue">
                          <Trans>prices.month</Trans>
                        </span>
                      </div>
                      <span className="price-bottom-text gr-text-11 mt-5 text-blackish-blue gr-opacity-7 d-inline-flex">
                        {isMonthly ? (
                          <Trans>prices.billed-y</Trans>
                        ) : (
                          <Trans>prices.billed-m</Trans>
                        )}
                      </span>
                      <ul className="card-list list-style-check pl-0 mt-7 mt-lg-11">
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>
                          <Trans>prices.l1</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>15{" "}
                          <Trans>prices.l2</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>1{" "}
                          <Trans>prices.l4s</Trans>
                        </li>

                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>1{" "}
                          <Trans>prices.l5s</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>1{" "}
                          <Trans>prices.l6s</Trans>
                        </li>
                        <li className="disabled gr-text-9">
                          <i className="icon icon-simple-remove"></i>{" "}
                          <Trans>prices.l4</Trans>
                        </li>
                        <li className="disabled gr-text-9">
                          <i className="icon icon-simple-remove"></i>
                          <Trans>prices.l7</Trans>
                        </li>
                        <li className="disabled gr-text-9">
                          <i className="icon icon-simple-remove"></i>
                          <Trans>prices.l8</Trans>
                        </li>
                      </ul>
                    </div>
                    <Link to="https://app.trustify.ch/#/auth/register">
                      <Button className="with-icon gr-hover-y px-xl-8 px-lg-4 px-sm-8 px-4">
                        <Trans>prices.button-1</Trans>
                        <i className="icon icon-tail-right font-weight-bold"></i>
                      </Button>
                    </Link>
                    <span className="btn-bottom-text d-block gr-text-11 text-blackish-blue gr-opacity-7 mt-5">
                      <Trans>prices.creditcard</Trans>
                    </span>
                  </div>
                </Col>
                <Col lg="3" md="6" sm="10" className="mb-9">
                  <div className="pricing-card bg-white gr-hover-shadow-1 border text-left pt-9 pb-9 pr-9 pr-xl-13  pl-9 pl-xl-13 bg-white rounded-10">
                    <div className="price-content">
                      <span className="small-title gr-text-12 text-primary font-weight-bold text-uppercase">
                        Lite
                      </span>
                      <div className="d-flex align-items-end mt-11 ">
                        <span className="currency mr-2 gr-text-6 font-weight-bold line-spacing-none text-blackish-blue">
                          <Trans>global.currency-i</Trans>
                        </span>
                        <h2 className="price-value gr-text-2 font-weight-bold line-spacing-none mb-0 text-blackish-blue">
                          {isMonthly ? (
                            <Trans>prices.p2y</Trans>
                          ) : (
                            <Trans>prices.p2m</Trans>
                          )}
                          <span className="d-none">.</span>
                        </h2>
                        <span className="per gr-text-9 text-blackish-blue">
                          <Trans>prices.month</Trans>
                        </span>
                      </div>
                      <span className="price-bottom-text gr-text-11 mt-5 text-blackish-blue gr-opacity-7 d-inline-flex">
                        {" "}
                        {isMonthly ? (
                          <Trans>prices.billed-y</Trans>
                        ) : (
                          <Trans>prices.billed-m</Trans>
                        )}
                      </span>
                      <ul className="card-list list-style-check pl-0 mt-7 mt-lg-11">
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>
                          <Trans>prices.l1</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>50{" "}
                          <Trans>prices.l2</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>3{" "}
                          <Trans>prices.l3</Trans>
                        </li>

                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>3{" "}
                          <Trans>prices.l5</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>1{" "}
                          <Trans>prices.l6s</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>{" "}
                          <Trans>prices.l4</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>
                          <Trans>prices.l7</Trans>
                        </li>
                        <li className="disabled gr-text-9">
                          <i className="icon icon-simple-remove"></i>
                          <Trans>prices.l8</Trans>
                        </li>
                      </ul>
                    </div>
                    <Link to="https://app.trustify.ch/#/auth/register">
                      <Button className="with-icon gr-hover-y px-xl-8 px-lg-4 px-sm-8 px-4">
                        <Trans>prices.button-2</Trans>
                        <i className="icon icon-tail-right font-weight-bold"></i>
                      </Button>
                    </Link>
                    <span className="btn-bottom-text d-block gr-text-11 text-blackish-blue gr-opacity-7 mt-5">
                      <Trans>prices.creditcard</Trans>
                    </span>
                  </div>
                </Col>
                <Col lg="3" md="6" sm="10" className="mb-9">
                  <div className="pricing-card bg-white gr-hover-shadow-1 border text-left pt-9 pb-9 pr-9 pr-xl-13  pl-9 pl-xl-13 bg-white rounded-10">
                    <div className="price-content">
                      <span className="small-title gr-text-12 text-primary font-weight-bold text-uppercase">
                        Premium
                      </span>
                      <div className="d-flex align-items-end mt-11 ">
                        <span className="currency mr-2 gr-text-6 font-weight-bold line-spacing-none text-blackish-blue">
                          <Trans>global.currency-i</Trans>
                        </span>
                        <h2 className="price-value gr-text-2 font-weight-bold line-spacing-none mb-0 text-blackish-blue">
                          {isMonthly ? (
                            <Trans>prices.p3y</Trans>
                          ) : (
                            <Trans>prices.p3m</Trans>
                          )}
                          <span className="d-none">.</span>
                        </h2>
                        <span className="per gr-text-9 text-blackish-blue">
                          <Trans>prices.month</Trans>
                        </span>
                      </div>
                      <span className="price-bottom-text gr-text-11 mt-5 text-blackish-blue gr-opacity-7 d-inline-flex">
                        {" "}
                        {isMonthly ? (
                          <Trans>prices.billed-y</Trans>
                        ) : (
                          <Trans>prices.billed-m</Trans>
                        )}
                      </span>
                      <ul className="card-list list-style-check pl-0 mt-7 mt-lg-11">
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>
                          <Trans>prices.l1</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>250{" "}
                          <Trans>prices.l2</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>4{" "}
                          <Trans>prices.l3</Trans>
                        </li>

                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>4{" "}
                          <Trans>prices.l5</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>1{" "}
                          <Trans>prices.l6s</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>{" "}
                          <Trans>prices.l4</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>
                          <Trans>prices.l7</Trans>
                        </li>
                        <li className="disabled gr-text-9">
                          <i className="icon icon-simple-remove"></i>
                          <Trans>prices.l8</Trans>
                        </li>
                      </ul>
                    </div>
                    <Link to="https://app.trustify.ch/#/auth/register">
                      <Button className="with-icon gr-hover-y px-xl-8 px-lg-4 px-sm-8 px-4">
                        <Trans>prices.button-2</Trans>
                        <i className="icon icon-tail-right font-weight-bold"></i>
                      </Button>
                    </Link>
                    <span className="btn-bottom-text d-block gr-text-11 text-blackish-blue gr-opacity-7 mt-5">
                      <Trans>prices.creditcard</Trans>
                    </span>
                  </div>
                </Col>
                <Col lg="3" md="6" sm="10" className="mb-9">
                  <div className="pricing-card bg-white gr-hover-shadow-1 border text-left pt-9 pb-9 pr-9 pr-xl-13  pl-9 pl-xl-13 bg-white rounded-10">
                    <div className="price-content">
                      <span className="small-title gr-text-12 text-primary font-weight-bold text-uppercase">
                        Enterprise
                      </span>
                      <div className="d-flex align-items-end mt-11">
                        <span className="currency mr-2 gr-text-6 font-weight-bold line-spacing-none text-blackish-blue">
                          <Trans>global.currency-i</Trans>
                        </span>
                        <h2 className="price-value gr-text-2 font-weight-bold line-spacing-none mb-0  text-blackish-blue">
                          {isMonthly ? (
                            <Trans>prices.p4y</Trans>
                          ) : (
                            <Trans>prices.p4m</Trans>
                          )}
                          <span className="d-none">.</span>
                        </h2>
                        <span className="per gr-text-9 text-blackish-blue">
                          <Trans>prices.month</Trans>
                        </span>
                      </div>
                      <span className="price-bottom-text gr-text-11 mt-5 text-blackish-blue gr-opacity-7 d-inline-flex">
                        {" "}
                        {isMonthly ? (
                          <Trans>prices.billed-y</Trans>
                        ) : (
                          <Trans>prices.billed-m</Trans>
                        )}
                      </span>
                      <ul className="card-list list-style-check pl-0 mt-7 mt-lg-11">
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>
                          <Trans>prices.l1</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>750{" "}
                          <Trans>prices.l2</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>15{" "}
                          <Trans>prices.l3</Trans>
                        </li>

                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>20{" "}
                          <Trans>prices.l5</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>5{" "}
                          <Trans>prices.l6</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>{" "}
                          <Trans>prices.l4</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>
                          <Trans>prices.l7</Trans>
                        </li>
                        <li className="gr-text-9">
                          <i className="icon icon-check-simple"></i>
                          <Trans>prices.l8</Trans>
                        </li>
                      </ul>
                    </div>
                    <Link to="https://app.trustify.ch/#/auth/register">
                      <Button className="with-icon gr-hover-y px-xl-8 px-lg-4 px-sm-8 px-4">
                        <Trans>prices.button-2</Trans>
                        <i className="icon icon-tail-right font-weight-bold"></i>
                      </Button>
                    </Link>
                    <span className="btn-bottom-text d-block gr-text-11 text-blackish-blue gr-opacity-7 mt-5">
                      <Trans>prices.creditcard</Trans>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Content;
