import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import imgB2 from "../../assets/image/l2/png/testimonial-brand-logo2.png";


const CTA = () => {
  return (
    <>
      {/* <!-- CTA Area --> */}
      <div className="cta-section overflow-hidden py-13 py-lg-25 bg-mirage bg-pattern pattern-1 ">
        <Container>
          <Row className="align-items-center">
            <Col xl="5" md="6" data-aos="fade-right" data-aos-duration="800">
              <div className="section-title pr-lg-7 mb-0 dark-mode-texts">
                <h2 className="title gr-text-4 mb-6">
                <Trans>marketing.title</Trans>
                </h2>
                <p className="gr-text-8 pr-lg-7 mb-0">
                <Trans>marketing.text</Trans>
                </p>
              </div>
            </Col>
            <Col
              xl="4"
              lg="5"
              md="6"
              className="offset-xl-3 offset-lg-1 mt-9 mt-md-0 dark-mode-texts"
              data-aos="fade-left"
              data-aos-duration="800"
            >

              <div className="single-testimonial h-100 d-flex flex-column px-5">
                <div className="brand-logo mb-2 mb-lg-4">
                  <img src={imgB2} alt="" />
                  <span className="user-rank gr-text-11 gr-text-color-opacity ml-2">
                  <Trans>marketing.stat1</Trans>
                    </span>
                </div>
                <p className="review-text mb-0 gr-text-8">
                <Trans>marketing.stat1-text</Trans>
                </p>
              </div>

              <div className="single-testimonial h-100 d-flex flex-column px-5 mt-10">
                <div className="brand-logo mb-2 mb-lg-4">
                  <img src={imgB2} alt="" />
                  <span className="user-rank gr-text-11 gr-text-color-opacity ml-2">
                  <Trans>marketing.stat2</Trans>
                    </span>
                </div>
                <p className="review-text mb-0 gr-text-8">
                <Trans>marketing.stat2-text</Trans>
                </p>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CTA;
