import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

const Testimonial = () => {
  return (
    <>
      {/* <!-- Testimonial Area --> */}
      <div className="testimonial-section pt-15 pt-lg-24 pb-lg-12 bg-default-1">
        <Container>
          <Row className="align-items-center justify-content-around">
            <Col lg="4" md="6" sm="9" className="mb-16">
              <div className="single-testimonial text-center h-100 d-flex flex-column px-5">
                <div className="brand-logo mb-6 mb-lg-8">
                  <h4 className="title gr-text-7 mb-0">
                    <Trans>testimonial.t1</Trans>
                  </h4>
                </div>
                <p className="review-text mb-0 gr-text-6 font-weight-bold gr-text-color">
                  “<Trans>testimonial.text1</Trans>”
                </p>
                <div className="user-block media pt-5 pt-lg-5 d-flex justify-content-center mt-auto">
                  <div className="user-text text-left align-self-center">
                    <center>
                      <h4 className="user-title gr-text-9 mb-0">bazaarvoice</h4>
                      <span className="user-rank gr-text-11 gr-text-color-opacity">
                        <a
                          href="http://media2.bazaarvoice.com/documents/Bazaarvoice_Conversation_Index_Volume8.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Trans>testimonial.link</Trans>
                        </a>
                      </span>
                    </center>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="9" className="mb-16">
              <div className="single-testimonial text-center h-100 d-flex flex-column px-5">
                <div className="brand-logo mb-6 mb-lg-8">
                  <h4 className="title gr-text-7 mb-0">
                    <Trans>testimonial.t2</Trans>
                  </h4>
                </div>
                <p className="review-text mb-0 gr-text-6 font-weight-bold gr-text-color">
                  “<Trans>testimonial.text2</Trans>”
                </p>
                <div className="user-block media pt-5 pt-lg-5 d-flex justify-content-center mt-auto">
                  <div className="user-text text-left align-self-center">
                    <center>
                      <h4 className="user-title gr-text-9 mb-0">BrightLocal</h4>
                      <span className="user-rank gr-text-11 gr-text-color-opacity">
                        <a
                          href="https://www.brightlocal.com/research/local-consumer-review-survey-2020/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Trans>testimonial.link</Trans>
                        </a>
                      </span>
                    </center>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="9" className="mb-16">
              <div className="single-testimonial text-center h-100 d-flex flex-column px-5">
                <div className="brand-logo mb-6 mb-lg-8">
                  <h4 className="title gr-text-7 mb-0">
                    <Trans>testimonial.t3</Trans>
                  </h4>
                </div>
                <p className="review-text mb-0 gr-text-6 font-weight-bold gr-text-color">
                  “<Trans>testimonial.text3</Trans>”
                </p>
                <div className="user-block media pt-5 pt-lg-5 d-flex justify-content-center mt-auto">
                  <div className="user-text text-left align-self-center">
                    <center>
                      <h4 className="user-title gr-text-9 mb-0">GatherUp</h4>
                      <span className="user-rank gr-text-11 gr-text-color-opacity">
                        <a
                          href="https://gatherup.com/100-online-review-statistics/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Trans>testimonial.link</Trans>
                        </a>
                      </span>
                    </center>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Testimonial;
