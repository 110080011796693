import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import imgC from "../../assets/image/l1/png/trustify-size-s.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22 bg-default-2">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="6" className="offset-lg-0 mt-10 mt-lg-0">
              <div
                className="content-img img-group-1"
                data-aos="zoom-in"
                data-aos-duration="750"
              >
                <img
                  className="img-fluid"
                  src={imgC}
                  alt="Trustify Business Sizes Illustration"
                />
              </div>
            </Col>
            <Col sm="10" lg="5" className="pr-lg-9">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">
                  <Trans>content3.title</Trans>
                </h2>
                <p className="gr-text-8 pr-4 mb-11">
                  <Trans>content3.subline1</Trans>
                  <br />
                  <br />
                  <Trans>content3.subline2</Trans>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
