import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import icon5 from "../../assets/image/inner/icon-comments.svg";
import icon1 from "../../assets/image/inner/icon-custom.svg";
import icon4 from "../../assets/image/inner/icon-data.svg";
import icon3 from "../../assets/image/inner/icon-europe.svg";
import icon6 from "../../assets/image/inner/icon-media.svg";
import icon2 from "../../assets/image/inner/icon-network.svg";

const Services = () => {
  return (
    <>
      {/* <!-- Services Area --> */}
      <div className="service-section pt-12 pb-7 pb-lg-25 pt-lg-19">
        <Container>
          <Row className="justify-content-center">
            <Col xl="6" lg="8" sm="10">
              <div className="section-title text-center mb-11 mb-lg-20">
                <h2 className="title gr-text-4 mb-7">
                  <Trans>services.title</Trans>
                </h2>
                <p className="gr-text-8 px-lg-7 px-xl-0">
                  <Trans>services.subline</Trans>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center">
            <Col lg="4" md="6" sm="6" xs="8" className=" mb-lg-18 mb-9">
              <div className="feature-card d-flex flex-column flex-md-row ">
                <div className="card-icon mr-8 mt-2 mb-9 mb-md-0">
                  <img src={icon1} alt="Icon Individual Profiles" />
                </div>
                <div className="card-texts">
                  <h3 className="gr-text-7 mb-7 gr-text-exerpt">
                    <Trans>services.s1t</Trans>
                  </h3>
                  <p className="gr-text-9 mb-0">
                    <Trans>services.s1c</Trans>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="8" className="mb-lg-18 mb-9">
              <div className="feature-card d-flex flex-column flex-md-row ">
                <div className="card-icon mr-8 mt-2 mb-9 mb-md-0">
                  <img src={icon2} alt="Icon For all requirements" />
                </div>
                <div className="card-texts">
                  <h3 className="gr-text-7 mb-7 gr-text-exerpt">
                    <Trans>services.s2t</Trans>
                  </h3>
                  <p className="gr-text-9 mb-0">
                    <Trans>services.s2c</Trans>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="8" className="mb-lg-18 mb-9">
              <div className="feature-card d-flex flex-column flex-md-row ">
                <div className="card-icon mr-8 mt-2 mb-9 mb-md-0">
                  <img src={icon3} alt="Icon Swiss standard" />
                </div>
                <div className="card-texts">
                  <h3 className="gr-text-7 mb-7 gr-text-exerpt">
                    <Trans>services.s3t</Trans>
                  </h3>
                  <p className="gr-text-9 mb-0">
                    <Trans>services.s3c</Trans>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="8" className="mb-lg-18 mb-9">
              <div className="feature-card d-flex flex-column flex-md-row ">
                <div className="card-icon mr-8 mt-2 mb-9 mb-md-0">
                  <img src={icon4} alt="Icon Surveys" />
                </div>
                <div className="card-texts">
                  <h3 className="gr-text-7 mb-7 gr-text-exerpt">
                    <Trans>services.s4t</Trans>
                  </h3>
                  <p className="gr-text-9 mb-0">
                    <Trans>services.s4c</Trans>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="8" className="mb-lg-18 mb-9">
              <div className="feature-card d-flex flex-column flex-md-row ">
                <div className="card-icon mr-8 mt-2 mb-9 mb-md-0">
                  <img src={icon5} alt="Icon Multilingual" />
                </div>
                <div className="card-texts">
                  <h3 className="gr-text-7 mb-7 gr-text-exerpt">
                    <Trans>services.s5t</Trans>
                  </h3>
                  <p className="gr-text-9 mb-0">
                    <Trans>services.s5c</Trans>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="8" className="mb-lg-18 mb-9">
              <div className="feature-card d-flex flex-column flex-md-row ">
                <div className="card-icon mr-8 mt-2 mb-9 mb-md-0">
                  <img src={icon6} alt="Icon Media" />
                </div>
                <div className="card-texts">
                  <h3 className="gr-text-7 mb-7 gr-text-exerpt">
                    <Trans>services.s6t</Trans>
                  </h3>
                  <p className="gr-text-9 mb-0">
                    <Trans>services.s6c</Trans>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Services;
