import { Link } from "gatsby";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import imgH from "../../assets/image/l2/jpg/trustify-header.jpg";



const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
        <div
          className="bg-image bg-gradient bg-gradient-black pb-17 pt-24 pt-lg-32 pb-lg-28"
          css={`
            background-image: url(${imgH});
          `}
        >
        <Container>
          <Row className="align-items-center">
            <Col
              md="9"
              lg="7"
              xl="6"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
            >
              <div className="hero-content section-title dark-mode-texts">
                <h1 className="gr-text-2 font-weight-bold mb-8">
                  <Trans>hero.headline</Trans>
                </h1>
                <p className="gr-text-8 pr-sm-7 pr-md-12">
                <Trans>hero.subline</Trans>
                </p>
                <div className="hero-btns mt-10">
                <Link to="https://app.trustify.ch/#/auth/register">
                  <Button className="with-icon gr-hover-y">
                  <Trans>hero.headline-button</Trans>
                    <i className="icon icon-tail-right font-weight-bold"></i>
                  </Button>
                </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;