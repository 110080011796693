import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageWrapper from "../components/PageWrapper";
import Alert from "../sections/landing11/Alert";
import Content1 from "../sections/landing11/Content1";
import Content2 from "../sections/landing11/Content2";
import Content3 from "../sections/landing11/Content3";
import CTA from "../sections/landing11/CTA";
import Fact from "../sections/landing11/Fact";
import Hero from "../sections/landing11/Hero";
import Newsletter from "../sections/landing11/Newsletter";
import Prices from "../sections/landing11/Prices";
import Services from "../sections/landing11/Services";
import Testimonial from "../sections/landing11/Testimonial";

const Indexpage = () => {
  return (
    <>
      <Helmet>
        <title>Trustify - Get your Customers trust with Trustify</title>
        <meta
          name="description"
          content="Trustify uses and generates valuable customer reviews and supports your company with modern marketing tools."
        />
        <meta
          name="keywords"
          content="trustify, customers trust, reviews, ratings, profiles, trust, customer surveys, surveys"
        />
      </Helmet>
      <PageWrapper
        headerConfig={{
          theme: "dark",
          align: "left",
          isFluid: true,
          button: "account", // cta, account, null
        }}
        footerConfig={{
          style: "style2",
          theme: "light",
        }}
      >
        <Header />
        <Hero />
        <Fact />
        <Content1 />
        <Content2 />
        <Content3 />
        <Services />
        <Prices />
        <Alert />
        <Testimonial />
        <CTA />
        <Newsletter />
        <Footer />
      </PageWrapper>
    </>
  );
};

export default Indexpage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
